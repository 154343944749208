<template>
    <v-container>
      <v-card flat color="green" max-width="700" class="mx-auto my-12 py-5 text-center" dark>
        <p class="titulo-success">Payment was processed correctly</p>
        <v-icon x-large color="white">
              mdi-check-outline
        </v-icon>
      </v-card>
        
        <br>
      <div class="text-center">

     
        <v-btn color="blue-grey" class="mx-auto white--text" to="/login">
          Go Dashboard
          <v-icon
            right
            dark
          >
            mdi-home
          </v-icon>
        </v-btn>
         </div>
        <!-- <a href="/login">Go App</a> -->
    </v-container>
</template>
<script>
import { functions } from '../../plugins/firebase'

export default {
  data() {
    return {
    }
  },
  created () {

  },
  methods: {

  },
  mounted() {

  }
}
</script>
<style>
  .titulo-success{
    font-size: 35px;
    font-weight: 500;

    margin: 5px;
  }
</style>